require('./bootstrap');
require('flowbite');

require('jquery');
require('./script');
import Alpine from 'alpinejs';

window.Alpine = Alpine;
Alpine.start();

