import Datepicker from '@themesberg/tailwind-datepicker/Datepicker';
import Ja from '@themesberg/tailwind-datepicker/locales/ja';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

dayjs.tz.setDefault("Asia/Tokyo");

const now = dayjs();
const minDate = now.subtract(120, 'year').format("YYYY/MM/DD");
const maxDate = now.format("YYYY/MM/DD");
// const defaultViewDate = now.subtract(20, 'year').format("YYYY/MM/DD");
const defaultViewDate = now.format("YYYY/MM/DD");

Object.assign(Datepicker.locales, Ja);
const datepickerEl = document.getElementsByClassName('date_picker');
if (datepickerEl.length) {
    Array.prototype.forEach.call(datepickerEl, function (item) {
        new Datepicker(item, {
            "language": "ja",
            "minDate": minDate,
            'maxDate': maxDate,
            "defaultViewDate": defaultViewDate,
            "format": "yyyy/mm/dd"
        });
    })
}

$( function (){
    if (!!alertType) {
        let alertText = ''
        for (var key in alertMessage) {
            alertText += alertMessage[key] + '<br>'
        }
        $('#alert_message').html(alertText);
        if (alertType == 'success') {
            $('#alert_bunner').addClass('bg-teal-100 border-l-4 border-teal-500 text-teal-900');
        }
        else if (alertType == 'error') {
            console.log(alertType == 'error');
            $('#alert_bunner').addClass('bg-orange-100 border-l-4 border-orange-500 text-orange-700');
        }
        $('#alert').removeClass('hidden');
        setTimeout(function () {
            $('#alert').fadeOut();
        }, 5000);
        $('#alert_close').on('click', function () {
            $('#alert').fadeOut();
        })
    }
    // rounded-b

    // productページのスクロール位置
    if (location.href.indexOf('products') == -1) {
        localStorage.removeItem('productsPosition');
        return false;
    }
    let targetId = localStorage.getItem('productsPosition');
    if (location.href.indexOf('products') > -1 && targetId) {
        $(window).scrollTop($('#' + targetId).position().top);
    }
    let button = $('a.position-reminder');
    button.on('click', function (e) {
        let parentId = $(this).parents('tr').attr('id');
        console.log(parentId);
        localStorage.setItem('productsPosition', parentId);
    });

    // select-all
    $('.select-all').on('change', function (e) {
        if ($(this).prop('checked')) {
            $('.checkbox').prop('checked', true);
        } else {
            $('.checkbox').prop('checked', false);
        }
    })

    $('.image-upload-btn').on('click', function () {
        //ajaxでのcsrfトークン送信
        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });

        let upLoaded = $(this).parents('.variant-image').find('.uploaded-image');
        let url = $(this).parents('form').find('.image-upload-url').val();
        let variantId = $(this).parents('form').find('.image-upload-variant-id').val();
        let fileData = $(this).parents('form').find('.image-upload-file')[0].files[0];
        let form = new FormData();
        form.append("file", fileData);
        form.append("variantId", variantId);

        $.ajax({
            type: "POST",
            url: url,
            data: form,
            processData: false,
            contentType: false,
            dataType: 'json',

            success: function (response) {
                if (response.success) {
                    // 画像上書き時
                    if (upLoaded.length > 0) {
                        console.log('update');
                        upLoaded.find('img').attr('src', response.src)
                    }
                    // 画像追加時
                    else {
                        console.log('new');
                        let box = $("<div></div>", {
                            "class": "uploaded-image"
                        });
                        let input = $("<input>", {
                            "type": "radio",
                            "name": "manage_image_id",
                            "value": response.id,
                            "id": "img" + response.id
                        });
                        let label = $("<img>", {
                            "src": response.src,
                            "data-image_id": response.id,
                            "alt": "",
                            "class": "w-20",
                        });
                        box.append(input);
                        box.append(label);
                        $('.image_select').append(box);
                    }


                } else {
                    //モーダルでのエラーメッセージ表示処理
                    var msg = "";
                    $.each(response.errors_message, function (i, v) {
                        msg += v + "<br>";
                    });
                    $('#searchErrorMessage').html(msg);
                    $('#searchErrorMessage').show();
                }
            },
            error: function (response) {
                //エラー時の処理
            }
        });
    })

    $('body').on('click', '.shopify_product_image .image_select img', function () {
        $(this).parents('.uploaded-image').find('input').prop('checked', true);
        $(this).parents('.shopify_product_image').find('.set_image').attr('src', $(this).attr('src'));
        console.log($(this));
        console.log($(this).data('image_id'));
    });

    $('.edit_btn').on('click', function(){
        let mode = $('input#mode').val();
        // console.log(mode.length);
        if(mode.length !== 0){
            if (mode == 'receipt'){
                $('form#edit input.receipt').remove();
                $('.receipt_input').each(function(index) {
                    let id = $(this).data("index");
                    let q = $(this).val();
                    if (q !== 0 && q.length !== 0){
                       $('form#edit').append('<input type="hidden" class="receipt" name="receipt['+id+']" value="'+q+'">'); 
                    }
                });
                $('.rec_ecres_input').each(function(index) {
                    let id = $(this).data("index");
                    let q = $(this).val();
                    if (q !== 0 && q.length !== 0){
                        $('form#edit').append('<input type="hidden" class="receipt" name="rec_ecres['+id+']" value="'+q+'">');
                    }
                });
                $('.rec_shopres_input').each(function(index) {
                    let id = $(this).data("index");
                    let q = $(this).val();
                    if (q !== 0 && q.length !== 0){
                        $('form#edit').append('<input type="hidden" class="receipt" name="rec_shopres_input['+id+']" value="'+q+'">');
                    }
                });
                $('input.comment').each(function(index) {
                    let id = $(this).data("index");
                    let q = $(this).val();
                    if (q.length !== 0){
                        $('form#edit').append('<input type="hidden" class="comment" name="comment['+id+']" value="'+q+'">');
                    }
                });
            }
            if (mode == 'shopres'){
                $('form#edit input.shopres').remove();
                $('.sro_input').each(function(index) {
                    let id = $(this).data("index");
                    let q = $(this).val();
                    if (q !== 0 && q.length !== 0){
                        $('form#edit').append('<input type="hidden" class="shopres" name="pcid['+id+']" value="'+q+'">');
                    }
                });
            }
            if (mode == 'available'){
                $('form#edit input.available').remove();
                $('.available_input').each(function(index) {
                    let id = $(this).data("index");
                    let q = $(this).val();
                    if (q !== 0 && q.length !== 0){
                        $('form#edit').append('<input type="hidden" class="available" name="pcid['+id+']" value="'+q+'">');
                    }
                });
            }
            $('form#edit').submit();
        }
    });

    // 入庫
    $('.reciept_btn').on('click', function(){
        // console.log('reciept');
        $('input#mode').val('receipt');
        $('td.col3').attr("colspan", "4");
        $('td.col4').attr("colspan", "5");
        $('td.receipt').css("display", "table-cell");
        $('.edit_btn').css("opacity", "1");

        // 入金前受付非表示
        $('td.sro').css("display", "none");
        // 店舗予約可能枚数非表示
        $('td.available').css("display", "none");
    });

    // 入金前受付
    $('.shopres_btn').on('click', function(){
        // console.log("sro");
        $('input#mode').val('shopres');
        $('td.col4').attr("colspan", "5");
        $('td.sro').css("display", "table-cell");
        $('.edit_btn').css("opacity", "1");

        // 入庫非表示
        $('td.col3').attr("colspan", "3");
        $('td.receipt').css("display", "none");
        // 店舗予約可能枚数非表示
        $('td.available').css("display", "none");
    });

    // 店舗予約可能枚数
    $('.available_btn').on('click', function(){
        // console.log("available");
        $('td.col4').attr("colspan", "5");
        $('input#mode').val('available');
        $('td.available').css("display", "table-cell");
        $('.edit_btn').css("opacity", "1");

        // 入庫非表示
        $('td.col3').attr("colspan", "3");
        $('td.receipt').css("display", "none");
        // 入金前受付非表示
        $('td.sro').css("display", "none");
    });
});
